@tailwind base;
@tailwind components;
@tailwind utilities;

.font-loader {
  font-family: 'Croissant One', cursive;
  font-size: 28px;
}
.font2{
  font-family: 'Croissant One', cursive;
}
.font1{
  font-family: 'Fuggles', cursive;
}
.font3{
  font-family: 'Mooli', sans-serif;
}
.font4{
  font-family: 'Rajdhani', sans-serif;
}
.popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding-right: 3vh;
  padding-top: 8vh;
}
@media (max-width:900px) {
  .popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    justify-content: baseline;
    align-items: baseline;
  } 
}
.popup-inner{
  position: absolute;
  padding: 20px;
  width: 100%;
  max-width: 640px;
  background: #020914;
}
@media (max-width:1400) {
  .popup-inner{position: absolute;
  padding: 20px;
  width: 100%;
  background: #00040a;}
}
.popup-inner.btn-close{
  position: absolute;
  top: 16px;
  right: 16px;
}



audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
background-color: #5f7973;
color: #6d2626;
}
::-webkit-scrollbar {
    width: 2.5px;
    height:3px ;
    border-radius: 2px;
  }
.player{
  position: fixed;
  bottom: 0;
  width: 100%;
  
}

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #454a4b;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #5f6264;
  }

